import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bench Press 8-8-8\\@70% 1RM`}</p>
    <p>{`Barbell Rows 8-8-8\\@70% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`400m Run`}</p>
    <p>{`21-Shoulder Press (95/65)`}</p>
    <p>{`21-Ring Rows`}</p>
    <p>{`21-Back Extensions`}</p>
    <p>{`400M Run`}</p>
    <p>{`15-Shoulder Press`}</p>
    <p>{`15-Ring Rows`}</p>
    <p>{`15-Back Extensions`}</p>
    <p>{`400M Run`}</p>
    <p>{`9-Shoulder Press`}</p>
    <p>{`9-Ring Rows`}</p>
    <p>{`9-Back Extensions`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Saturday is our free to all workout at our normal class times of
8:00 & 9:00am at The Ville and 10:00 & 11:00am at East.  Bring a
friend!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`There will be no CrossFit Kids this Saturday.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      